* {
  margin: 0;
  scroll-behavior: smooth;
}

.clearfix {
  clear: both;
}

a {
  text-decoration: none;
}

.topnav {
  margin-left: 37%;
  z-index: 1;
}

.topnav a {
  text-decoration: none;
  color: black;
  margin-right: 3pc;
  font-family: Georgia;
}

.list_ancher a {
  text-decoration: none;
}

.list_ancher span {
  text-decoration: underline;
}

/* Home Page Slider */

.header-slider {
  overflow: hidden;
  position: relative;
}

.header-slider figure {
  position: relative;
  width: 500%;
  margin: 0;
  left: 0;
  animation: 20s slider infinite;
  overflow: hidden;
}

.header-slider figure img {
  width: 20%;
  height: 95vh;
  float: left;
}

@keyframes slider {
  0% {
    left: 0;
  }
  9.99% {
    left: 0;
  }
  10% {
    left: -100%;
  }
  19.99% {
    left: -100%;
  }
  20% {
    left: -200%;
  }
  39.99% {
    left: -200%;
  }
  40% {
    left: -300%;
  }
  59.99% {
    left: -300%;
  }
  60% {
    left: -400%;
  }
  79.99% {
    left: -400%;
  }
}

.header-slider-button-container {
  position: fixed;
  left: -3.5%;
  top: 50%;
  /* width: 23%; */
}

.header-slider-button-container button {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.header-slider-text-container {
  background-color: #e7e7e7;
  padding: 20px;
  position: absolute;
  right: 1%;
  bottom: 2%;
  width: 23%;
}

.header-slider-text-container h1 {
  font-size: 5.5vh;
  letter-spacing: 2px;
}

/* -------- Get Expert Advice Form -------- */

.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0.5);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.contact-form-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  overflow: none;
}

#get-expert-advice-form {
  display: none;
}

.contact-form {
  background-color: #fefefe;
  margin: 2% auto 2% auto;
  width: 30%;
  text-align: center;
  padding: 7vh 3vh;
  overflow: hidden;
  border-radius: 4px;
  z-index: 99;
}

.close-expert-advice-form {
  position: relative;
  color: red;
  font-size: 12px;
  cursor: pointer;
}

.contact-form img {
  max-width: 25%;
}

.contact-form h4 {
  font-size: 3.65vh;
  margin: 7.61vh auto 1.21vh;
  width: 80%;
}

.contact-form p {
  font: 2.43vh;
  margin: 0px auto;
  width: 80%;
  font-weight: bold;
  color: #717171;
  line-height: 3.65vh;
}

.contact-form input {
  font-size: 2.43vh;
  font-family: inherit;
  width: 80%;
  padding: 1vh;
  border: none;
  border-bottom: 1px solid #04bbb1;
  margin-top: 2vh;
  outline: none;
}

.contact-form button {
  width: 83%;
  margin-top: 4.56vh;
  margin-bottom: 1vh;
  padding: 1.43vh 0vh;
  font-size: 2vh;
  color: white;
  background-color: rgb(60, 140, 172);
  border: none;
  border-radius: 4px;
  font-family: inherit;
  outline: none;
}

/* Contact Us Page */

.contact_address {
  background-color: rgb(60, 140, 172);
}

/* About Us Page */

.first_paragraph {
  font-family: Georgia !important;
  font-size: 28px !important;
  text-align: left !important;
  color: #1f1a17 !important;
  max-width: 1270px !important;
  line-height: 1.2;
  margin: 0;
}

/* Our Projects Page */

.our-projects {
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 20vh;
}

.our-projects h1 {
  font-size: 4rem;
}

.card {
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 95%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container,
.container-2 {
  padding: 20px 0px;
}

.card h4 {
  margin: 3vh 0vh;
}

.card .container figure {
  position: relative;
  width: 200%;
  margin: 0;
  left: 0;
  animation: 5s project-slider infinite;
  overflow: hidden;
}

.card .container figure img {
  width: 50%;
  height: 30vh;
  float: left;
}

@keyframes project-slider {
  0% {
    left: 0;
  }
  49.99% {
    left: 0;
  }
  50% {
    left: -100%;
  }
  100% {
    left: -100%;
  }
}

/* // Container - 2 // */

.card .container-2 figure {
  position: relative;
  width: 300%;
  margin: 0;
  left: 0;
  animation: 5s project-slider-2;
}

.card .container-2 figure img {
  width: 33%;
  height: 30vh;
  float: left;
}

@keyframes project-slider-2 {
  0% {
    left: 0;
  }
  33.33% {
    left: 0;
  }
  33.34% {
    left: -100%;
  }
  66.66% {
    left: -100%;
  }
  66.67% {
    left: -200%;
  }
  100% {
    left: -200%;
  }
}

/* Individual Project Pages */

.bg-valley {
  background-image: url("../src/Photos/Header\ Slider/dlfValley/1.jpg");
  width: 100%;
  height: 70vh;
  background-position: center;
  background-size: cover;
  position: relative;
}
.bg-valley-gardens {
  background-image: url("../src/Photos/Header\ Slider/theValleyGarden/home.jpg");
  width: 100%;
  height: 70vh;
  background-position: center;
  background-size: cover;
  position: relative;
}
.bg-trident-hills {
  background-image: url("../src/Photos/Header\ Slider/trident/1.jpg");
  width: 100%;
  height: 70vh;
  background-position: center;
  background-size: center;
  position: relative;
}

* {
  box-sizing: border-box;
}
body {
  font-family: Verdana, sans-serif;
}
.mySlides {
  display: none;
}
img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}
.content-sub-contain {
  position: absolute;
  top: 10%;
  left: 10%;
  padding: 20px 0px;
}

.left-detail-box {
  width: 60%;
  float: left;
  color: black;
  margin-bottom: 10vh;
}

.other-course-details-banner {
  margin-top: 10px;
}

.main-desc-course {
  color: black;
  width: 100%;
  margin-top: 30%;
}

.what-you-learn {
  border: 1px solid black;
  padding: 20px;
  height: 30vh;
  margin-top: 60vh;
}

.what-you-learn ul li {
  font-size: 14px;
  list-style: none;
  margin-bottom: 6px;
}

.what-you-learn ul li::before {
  content: "\2713";
  margin-right: 8px;
}

.description {
  margin-top: 10%;
}

.price-box {
  width: 25%;
  float: right;
  margin-right: 3%;
  border: 1px solid white;
  position: sticky;
  top: 4vh;
  border-radius: 10px;
  background: #f3f3f3;
  padding: 20px 10px;
}

.price-box img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.price-box-details {
  width: 90%;
  margin: auto;
}

.btn {
  margin: 10px 0px;
  width: 100%;
}

.price-row {
  text-align: left;
}

.price-row div {
  text-align: left;
  margin: auto;
}

.price-box-ul {
  margin: 10px 0px;
}

.checked {
  color: orange;
}

/* Admin */

.bg-skyblue {
  background-color: rgb(60, 140, 172);
  height: 100vh;
  padding-top: 15vh;
}

.login-card {
  margin: auto;
  width: 35%;
  padding: 4rem 2rem;
  background-color: #fff;
  border-radius: 10px;
}

.login-card img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}

.login-card form img {
  margin-bottom: 5vh;
}

.login-card form button {
  width: 100%;
}

.list_ancher span {
  text-decoration: none;
}

.gallary {
  margin-top: 10vh;
}

.gallary .row {
  margin-top: 1vh;
}

.gallary .col-md-4 {
  margin-left: 2vw;
}

.gallary img {
  height: 20vh;
  width: 20vw;
  max-width: 20vw;
  max-height: 20vh;
}

/* $$$$$$$$ Mobile View $$$$$$$$ */

@media only screen and (max-width: 786px) {
  /* Image Slider */

  .header-slider figure img {
    margin-top: 15vh;
    height: 30vh;
    float: left;
  }

  /* Image Slider Form and Button */

  .header-slider-text-container,
  .header-slider-button-container {
    display: none;
  }

  /* Free Consulttion Form */

  .contact-form {
    margin: 14% auto 2% auto;
    width: 90%;
    padding: 7vh 1vh;
  }
  .close-expert-advice-form {
    color: red;
    font-size: 2vh;
  }
  .contact-form img {
    max-width: 35%;
  }
  .contact-form h4 {
    font-size: 2.89vh;
    margin: 2.89vh auto 1.21vh;
    width: 90%;
  }
  .contact-form p {
    font: 2.43vh;
    margin: 0px auto;
    width: 92%;
  }
  .contact-form input {
    font-size: 2vh;
    width: 90%;
    margin-top: 3vh;
  }
  .contact-form button {
    width: 93%;
    margin-top: 4.56vh;
    padding: 1.5vh 0vh;
    font-size: 2vh;
  }
}
